.nav {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 100;
  border-bottom: 1px solid #777093;
}

#disclaimer {
  font-size: 0.6em;
  color: rgb(255, 142, 249);
  margin-top: 20px;
  text-transform: uppercase;
  font-style: italic;
}

#name-label a {
  color: var(--lt-pink)!important;
  letter-spacing: 0.5px!important;
  font-weight: 300;
  display: flex;
  font-size: 1.775rem;
  font-family: 'Work Sans', sans-serif;
}

span#name-label:hover {
  cursor: default;
}

#descriptors {
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.5px;
  margin: 12px 0px 22px 0px;
  font-size: 0.8rem;
  /* font-style: italic; */
  color: #b6b6ff;
}

.nav-items ul {     
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.nav-items li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  margin-left: 1em;
  font-size: 1.2rem;
}
 
a, a:visited { 
  text-decoration: none;
  color: var(--lt-pink)!important;
  text-transform: uppercase;
}

.nav-link.active:after {
  content: '';
  position: absolute;
  left: 0;
  animation: animate-underline .35s 1 forwards;
  animation-timing-function: ease-in-out;
  bottom: -2px;
  height: 1px;
  background: var(--lt-pink);
  /* width: 100%; */
}

@keyframes animate-underline {
  0% { 
    /* left: -100%;  */
    width: 0%; 
  }
  100% { 
    /* left : 0;  */
    width: 100%; 
  }
}

@media screen and (max-width: 600px) {
  #name-label a {
    font-size: 1.8em;
    color: var(--lt-pink)!important;
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .nav {
    flex-direction: column;
    margin-bottom: 1.8em;
  }

  #descriptors { 
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .nav-items {
    margin-top: 2vh;
    margin-left: 2vw;
    display: inline-flex;
    margin-right: 2vw;
    margin-bottom: 6vw;
    align-self: flex-start;
  }

  .nav-items ul {
    margin-left: -1em;
  }
}