.inner-page-container {
  overflow-x: auto;
}

.page-header {
  position: relative;
}

h1.page-header-label {
  font-size: 28px;
  text-transform: uppercase;
  color: var(--lt-pink);
  display: inline-block;
  font-weight: 100;
}

.project-header-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.page-header-divider {
  display: inline-block;
	height: 2px;
	background: var(--lt-pink);
  width: 48px;
  left: 128px;
	position: absolute;
	top: 50%;
}

div.page-content {
  line-height: 26px;
  max-width: 50%;
}

div.page-content.about, .additional-info {
  width: 45%;
}

div.page-content.about p {
  font-size: 1.0em;
  line-height: 1.8em;
  margin-top: 2.0em;
  font-family: 'Work Sans', sans-serif;
}

.additional-info div {
  margin-bottom: 64px;
}

.additional-info .last-updated {
  font-size: 0.85em;
}

#about h3, #play h3 {
  color: rgb(136, 255, 206);
}

.additional-info ul li {
  font-family: 'Work Sans', sans-serif;
  list-style-type: none;
  margin-bottom: 12px;
  position: relative;
}

.additional-info ul {
  padding: 0;
  margin-left: 0;
  position: relative;
}

#about {
  margin: 40px 2vw;
  display: flex;
  justify-content: space-around;
}

#about a {
  text-transform: none;
  position: relative;
  line-height: 24px;
}

#about b {
  color: #b6b6ff;
  font-style: italic;
}

#about a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 1px;
  background: var(--lt-pink);
  width: 100%;
}


#play {
  margin: 40px 2vw;
  display: flex;
  flex-direction: column;
}

.play-description {
  width: 45%;
  margin-left: 16px;
  font-size: 1.2em;
  line-height: 1.5em;
  margin-bottom: 40px;
}

.play-img-container {
  width: calc(100% / 3);
  padding: 16px;
}

.play-items-grid {
  display: flex;
  flex-wrap: wrap;
}

img.play-img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  #about {
    flex-direction: column;
  }

  div.page-content.about, .additional-info {
    width: 100%;
    max-width: 100%!important;
  }

  div.page-content {
    margin-left: 0px;
    margin-right: 0px;
  }

  div.page-content.about p {
    font-size: 0.9em;
    line-height: 1.6em;
  }

  .play-description {
    width: 100%;
    margin-left: 0;
  }

  .play-img-container {
    width: 100%;
    padding: 16px 0px;
  }
}