@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap);
:root {
  /* Current theme */
  --lt-pink: #efe8ff;
  --theme-dark-bg: #2f2f3b;


  /* Light theme */
  --theme-light-bg: #f4f5f2;

  background: #2f2f3b;

  background: var(--theme-dark-bg);
}

:root::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

html, body {
  background: #2f2f3b;
  background: var(--theme-dark-bg);
  margin: 0 auto;
  height: 100%;
  font-family: 'IBM Plex Mono', monospace;
  color: #efe8ff;
  color: var(--lt-pink);
}

body {
  position: relative;
  height: 100%;
  background: var(--theme-dark-bgk);
}

p {
  color: #efe8ff;
  color: var(--lt-pink);
  font-size: 0.8em;
}

#app {
  min-height: 100%;
  padding: 2.5em 4em;
  background: #2f2f3b;
  background: var(--theme-dark-bg);
}

@media screen and (max-width: 600px) {
  #app {
    padding: 1.4em;
  }
}

#canvas {
  position: absolute;
}
#info {
  max-width: 460px;
  line-height: 22px;
  position: absolute;
  bottom: 0;
}

.borderline {
  position: relative;
  height: 2px;
  margin-bottom: 24px;
}

span#info-label {
  color: var(--lt-pink);
  letter-spacing: 1.2px;
  font-size: 22px;
}

div#info-content {
  margin-top: 24px;
  margin-bottom: 28px;
}

div#info-content p {
  font-size: 0.8em;
}

img#ig-logo {
  height: 22px;
  width: 22px;
  margin-right: 16px;
}

img#email-logo {
  height: 22px;
}
.nav {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 100;
  border-bottom: 1px solid #777093;
}

#disclaimer {
  font-size: 0.6em;
  color: rgb(255, 142, 249);
  margin-top: 20px;
  text-transform: uppercase;
  font-style: italic;
}

#name-label a {
  color: var(--lt-pink)!important;
  letter-spacing: 0.5px!important;
  font-weight: 300;
  display: flex;
  font-size: 1.775rem;
  font-family: 'Work Sans', sans-serif;
}

span#name-label:hover {
  cursor: default;
}

#descriptors {
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.5px;
  margin: 12px 0px 22px 0px;
  font-size: 0.8rem;
  /* font-style: italic; */
  color: #b6b6ff;
}

.nav-items ul {     
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.nav-items li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  margin-left: 1em;
  font-size: 1.2rem;
}
 
a, a:visited { 
  text-decoration: none;
  color: var(--lt-pink)!important;
  text-transform: uppercase;
}

.nav-link.active:after {
  content: '';
  position: absolute;
  left: 0;
  -webkit-animation: animate-underline .35s 1 forwards;
          animation: animate-underline .35s 1 forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  bottom: -2px;
  height: 1px;
  background: var(--lt-pink);
  /* width: 100%; */
}

@-webkit-keyframes animate-underline {
  0% { 
    /* left: -100%;  */
    width: 0%; 
  }
  100% { 
    /* left : 0;  */
    width: 100%; 
  }
}

@keyframes animate-underline {
  0% { 
    /* left: -100%;  */
    width: 0%; 
  }
  100% { 
    /* left : 0;  */
    width: 100%; 
  }
}

@media screen and (max-width: 600px) {
  #name-label a {
    font-size: 1.8em;
    color: var(--lt-pink)!important;
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .nav {
    flex-direction: column;
    margin-bottom: 1.8em;
  }

  #descriptors { 
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .nav-items {
    margin-top: 2vh;
    margin-left: 2vw;
    display: inline-flex;
    margin-right: 2vw;
    margin-bottom: 6vw;
    align-self: flex-start;
  }

  .nav-items ul {
    margin-left: -1em;
  }
}
.inner-page-container {
  overflow-x: auto;
}

.page-header {
  position: relative;
}

h1.page-header-label {
  font-size: 28px;
  text-transform: uppercase;
  color: var(--lt-pink);
  display: inline-block;
  font-weight: 100;
}

.project-header-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.page-header-divider {
  display: inline-block;
	height: 2px;
	background: var(--lt-pink);
  width: 48px;
  left: 128px;
	position: absolute;
	top: 50%;
}

div.page-content {
  line-height: 26px;
  max-width: 50%;
}

div.page-content.about, .additional-info {
  width: 45%;
}

div.page-content.about p {
  font-size: 1.0em;
  line-height: 1.8em;
  margin-top: 2.0em;
  font-family: 'Work Sans', sans-serif;
}

.additional-info div {
  margin-bottom: 64px;
}

.additional-info .last-updated {
  font-size: 0.85em;
}

#about h3, #play h3 {
  color: rgb(136, 255, 206);
}

.additional-info ul li {
  font-family: 'Work Sans', sans-serif;
  list-style-type: none;
  margin-bottom: 12px;
  position: relative;
}

.additional-info ul {
  padding: 0;
  margin-left: 0;
  position: relative;
}

#about {
  margin: 40px 2vw;
  display: flex;
  justify-content: space-around;
}

#about a {
  text-transform: none;
  position: relative;
  line-height: 24px;
}

#about b {
  color: #b6b6ff;
  font-style: italic;
}

#about a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 1px;
  background: var(--lt-pink);
  width: 100%;
}


#play {
  margin: 40px 2vw;
  display: flex;
  flex-direction: column;
}

.play-description {
  width: 45%;
  margin-left: 16px;
  font-size: 1.2em;
  line-height: 1.5em;
  margin-bottom: 40px;
}

.play-img-container {
  width: calc(100% / 3);
  padding: 16px;
}

.play-items-grid {
  display: flex;
  flex-wrap: wrap;
}

img.play-img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  #about {
    flex-direction: column;
  }

  div.page-content.about, .additional-info {
    width: 100%;
    max-width: 100%!important;
  }

  div.page-content {
    margin-left: 0px;
    margin-right: 0px;
  }

  div.page-content.about p {
    font-size: 0.9em;
    line-height: 1.6em;
  }

  .play-description {
    width: 100%;
    margin-left: 0;
  }

  .play-img-container {
    width: 100%;
    padding: 16px 0px;
  }
}
#projects {
  display: flex;
  padding: 4vh 0vh 0vh 0vh;
  margin: 0vw 2vw;
}

.projects-view-list {
  justify-content: center;
  flex-direction: column;
}

.projects-view-grid {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-client, .project-years {
  font-size: 1.2em!important;
}

.back-header {
  margin: 3vh 0px;
  display: flex;
  align-items: center;
}

.back-label {
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-left: 16px;
}

.project-tools {
  font-style: italic;
}

.project-content p {
  font-size: 1em;
  line-height: 1.6em;
  color: var(--lt-pink);
}

.project-content {
  margin-bottom: 3.5em;
  display: flex;
  flex-direction: column;
}

.project-container {
  overflow: hidden;
  margin-bottom: 64px;
}

.project-content-imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-bottom: 60px; */
}

.LazyLoad.proj-img-container.full {
  width: 100%;
}

.LazyLoad.proj-img-container.half {
  width: 50%;
  padding: 16px;
}

.project-img {
  width: 72%;
  float: left;
}

img.project-highlight {
  width: 100%;
  height: auto;
}

.project-meta {
  float: right;
  width: 25%;
  overflow:hidden;
}

.project-description {
  border: 2px solid var(--lt-pink);
  padding: 12px;
  margin-top: -2px;
}

.proj-img {
  width: 100%;
}

.project-description p {
  color: var(--lt-pink);
  margin: 0px;
  line-height: 22px;
}


/* FILTERS */
#project-filters-container {
  margin-top: 4.5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul#project-filters {
  display: flex;
  list-style-type: none;
  padding: 0;
  /* justify-content: space-between; */
}

li.project-filter {
  padding: 6px 16px;
  font-size: 0.875em;
  margin-right: 24px;
  text-transform: uppercase;
}

li.project-filter.active {
  background: #5d5677;
  border-radius: 50px;
}

li.project-filter:hover {
  cursor: pointer;
}

.grid-list-icons {
  display: flex;
}

#icon-grid:hover, 
#icon-list:hover {
  cursor: pointer;
}

#icon-list {
  margin-right: 18px;
}


/* GRID VIEW */
.content-item-outer {
  margin-bottom: 8em;
  padding: 0px;  
}

.content-item-outer.list {
  width: 100%;
}

.content-item-outer.grid {
  width: 31%;
}

.content-item-outer.placeholder {
  margin-bottom: 0px;
}

.content-item-list,
.content-item-grid {
  display: flex;
}

.content-item-grid {
  flex-direction: column;
}

.content-item-list > .hero-container {
  width: 72%;
}

.content-item-grid > .hero-container,
.content-item-grid > .project-meta-container {
  width: 100%;
}

.content-item-grid > .project-meta-container > .lower-section {
  display: none;
}

.content-item-grid > .hero-container > a > .LazyLoad {
  padding: 0px!important;
}

.content-item-outer.grid.inactive,
.content-item-outer.list.inactive {
  display: none;
}

.LazyLoad {
  width: 100%;
  padding: 0px 30px 0px 0px;  
}

.proj-collaborators {
  font-family: "IBM Plex Mono", monospace!important;
  font-style: italic;
  font-size: 0.85em!important;
  margin-top:1em!important;
  margin-bottom:2em!important;
}


p.project-desc {
  font-size: 0.875em;
  line-height: 1.6em;
  font-family: 'Work Sans', sans-serif;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.project-img-grid {
  width: 100%;
  /* box-shadow: 5px 5px 10px #19181d!important; */
}

.project-meta-container {
  width: 28%;
  margin: 24px 0px;
  display: flex;
  flex-direction: column;
}

.project-name {
  text-transform: none;
  color: var(--lt-pink);
  font-family: 'Work Sans', sans-serif;
}

.project-link {
  color: #a3fffb!important;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  margin: 4px 0px 0px 0px;
}

.project-role {
  font-style: italic;
}

.project-desc-container p {
  margin: 0px;
  line-height: 1.6em;
  font-size: 0.775em;
  color: var(--lt-pink);
}

.upper-section p {
  margin: 8px 0px;
}

.lower-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.readMore {
  text-transform: none !important;
  margin-top: 30px;
  display: inline;
  position: relative;
  /* text-decoration: underline; */
  font-size: 0.9em;
  color: #a3fffb!important;
}

.readMore:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 1px;
  background: #a3fffb;
  width: 100%;
}

.line-deco {
  width: 48px;
  margin: 16px 0px;
  height: 1px;
  background: white;
}


.project-content-container {
  margin: 6vw 8vw;
}

.back-arrow-icon {
  width: 24px;
}

.project-detail-name {
  margin: 0;
  font-size: 1.6rem;
  font-family: 'Work Sans', sans-serif;
  color: rgb(136, 255, 206);
}

.project-detail-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.project-detail-link {
  display: inline-block;
  padding: 8px 16px;
  font-size: 1em;
  margin-top: 36px;
  margin-bottom: 36px;
  border: 1px solid var(--lt-pink);
  border-radius: 3px;
  background: var(--lt-pink);
  font-size: 0.875em;
}

div.vid {
  position: relative;
  overflow: hidden;
  padding-top: 62.25%;
  margin-bottom: 60px;
}

div.vid.cepheids {
  background: black;
}

div.vid.propagator {
  padding-top: 75%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.player {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.project-content-desc {
  /* color: var(--lt-pink);
  width: 40%;
  margin: 60px 0px; */
  color: var(--lt-pink);
  width: 75%;
  align-self: center;
  margin: 60px 0px;
}

.project-content-desc p {
  font-size: 1.2em;
}

.project-content-tools {
  color: var(--lt-pink);
  width: 20%;
  border-top: 0.5px solid var(--lt-pink);
  padding-top: 8px;
}


.project-content-tools ul {
  margin: 12px 0px;
  padding: 0px;
}

.project-content-tools ul li {
  list-style-type: none;
  font-size: 0.8em;
  margin-top: 4px;
  margin-bottom: 4px;
  color: var(--lt-pink);
}

.project-content-pubs {
  max-width: 70%;
}

.project-content-pubs li {
  font-size: 0.8em;
  line-height: 1.6em;
  margin-bottom: 12px;
}

@media screen and (max-width: 980px) {
  .content-item-outer {
    width: 85%;
    min-width: 100%;
    margin-bottom: 5em;
  }

  .placeholder {
    margin-bottom: 0;
    display: none;
  }

  .project-detail-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .project-detail-link {
    /* margin-bottom: 0px; */
    margin-top: 0px;
  }
  
  .project-detail-container {
    max-width: 100%;
    margin-top: 0px;
  }
  
  .back-icon {
    display: none;
  }

  .project-content-desc,
  .project-content-tools {
    width: 100%;
  }

  .LazyLoad.proj-img-container.half  {
    width: 100%;
    padding: 8px 0px;
  }

  .project-content-pubs {
    max-width: 100%;
    margin-right: 16px;
  }
}

@media screen and (max-width: 1400px) {
  .content-item-list,
  .content-item-grid {
    flex-direction: column;
  }

  .hero-container, .project-meta-container {
    width: 100%!important;
  }

  .LazyLoad {
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  div.vid.propagator {
    padding-top: 195%;
  }

  #project-filters-container {
    display: none;
  }
}

