#info {
  max-width: 460px;
  line-height: 22px;
  position: absolute;
  bottom: 0;
}

.borderline {
  position: relative;
  height: 2px;
  margin-bottom: 24px;
}

span#info-label {
  color: var(--lt-pink);
  letter-spacing: 1.2px;
  font-size: 22px;
}

div#info-content {
  margin-top: 24px;
  margin-bottom: 28px;
}

div#info-content p {
  font-size: 0.8em;
}

img#ig-logo {
  height: 22px;
  width: 22px;
  margin-right: 16px;
}

img#email-logo {
  height: 22px;
}