@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');


:root {
  /* Current theme */
  --lt-pink: #efe8ff;
  --theme-dark-bg: #2f2f3b;


  /* Light theme */
  --theme-light-bg: #f4f5f2;

  background: var(--theme-dark-bg);
}

:root::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

html, body {
  background: var(--theme-dark-bg);
  margin: 0 auto;
  height: 100%;
  font-family: 'IBM Plex Mono', monospace;
  color: var(--lt-pink);
}

body {
  position: relative;
  height: 100%;
  background: var(--theme-dark-bgk);
}

p {
  color: var(--lt-pink);
  font-size: 0.8em;
}

#app {
  min-height: 100%;
  padding: 2.5em 4em;
  background: var(--theme-dark-bg);
}

@media screen and (max-width: 600px) {
  #app {
    padding: 1.4em;
  }
}

#canvas {
  position: absolute;
}